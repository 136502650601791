@keyframes skills-animation {
  0% {
    opacity: .5;
    transform: scale(.5);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.skills {
  margin: 40px auto;
  padding: 20px;
  max-width: 1000px;
  width: 100%;
}

.skills-title {
  color: #464f63;
  margin: 25px 0;
  font-size: 25px;
  text-align: left;
  opacity: .7;
}

.skills-list {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 10%);
  grid-gap: 3rem;
  justify-content: space-between;
}

.skill {
  animation: skills-animation 1s forwards;
  position: relative;
  padding-bottom: 10px;
}

@media (max-width: 1000px) {

  .skills-title {
    padding: 0;
    text-align: center;
  }

  .skills-list {
    grid-template-columns: repeat(auto-fill, 20%);
  }
}

@media (max-width: 600px) {

  .skills-list {
    grid-template-columns: repeat(auto-fill, 33.3%);
    justify-content: space-around;
  }
}

.skill-icon {
  fill: rgba(70, 79, 99, 0.9);
  font-size: 60px;
  transition: fill .2s ease-in-out;
  display: block;
}

.skill-icon:hover {
  fill: rgba(70, 79, 99, 1);
}

.skill-name {
  display: block;
  padding: 2px 0 0;
  font-size: 12px;
  letter-spacing: .1rem;
  color: #464f63;
}

.skill-level {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 5px;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(auto-fill, 30%);
  justify-content: space-between;
}

.level-indicator {
  transition: opacity .2s ease-in-out;
  height: 3px;
  transform: skewX(-15deg);
  background-color: #464f63;
  opacity: 0;
  transition-delay: 0;
}

.level-indicator + .level-indicator {
  transition-delay: .1s;
}

.level-indicator + .level-indicator + .level-indicator {
  transition-delay: .2s;
}

.skill:hover .level-indicator {
  opacity: .7;
}
