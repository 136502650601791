.background {
  position: relative;
  width: auto;
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #2b313e;
}
