html, body {
  margin: 0;
  font-family: 'Scope One', serif;
  font-weight: 200;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: .2rem;
  margin: 0;
}

a, p {
  margin: 0;
}

h2, span {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}
