.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.App-wrap {
  position: relative;
  z-index: 2;
}

* {
  box-sizing: border-box;
}
