@keyframes sprite-animation {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: scale(.5);
  }

  50% {
    visibility: visible;
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes text-animation {

  0% {
    visibility: hidden;
    opacity: 0;
    transform: scale(.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.header {
  padding: 0;
  margin: 0;
  height: 100vh;
  margin: 0 auto;
  background-color: #2b313e;
  color: #eee;
  position: relative;
}

.wrap {
  position: absolute;
  max-width: 840px;
  width: 100%;
  padding: 0 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.sprite {
  border-radius: 50%;
  animation: sprite-animation .25s linear;
  border: 5px solid #fff;
}

.name {
  visibility: hidden;
  opacity: 0;
  font-size: 35px;
  margin:  40px auto 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: .2rem;
  color: #FF1493;
  animation: text-animation 1s forwards;
}

.job {
  visibility: hidden;
  opacity: 0;
  font-size: 50px;
  margin: 20px auto 0;
  padding: 0;
  animation: text-animation .5s forwards;
  animation-delay: .5s;
}

.location {
  visibility: hidden;
  opacity: 0;
  font-size: 12px;
  margin: 0 auto 0;
  padding: 20px 0;
  animation: text-animation .5s forwards;
  animation-delay: .6s;
}

.cta {
  color: #fff;
  text-decoration: none;
  font-size: 35px;
  line-height: 35px;
  padding: 15px 20px;
}

.cta:hover {
  border: 1px solid #fff;
  background-color: #fff;
  color: #333;
}

.blurb {
  border-top: 5px solid #fff;
  font-size: 35px;
  margin: 0 auto;
  padding: 30px 20px 0;
}

@media (max-width: 800px) {

  .name {
    font-size: 20px;
  }

  .job {
    font-size: 40px;
  }
}
